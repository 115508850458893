import { writeErr } from "../components/common/logger";
import * as isaac from 'isaac';
import DOMPurify from 'dompurify';

const langMap = { ".sk": "sk-SK", ".cz": "cs-CZ", ".en": "en-US", ".de": "de-DE" };
const momentLocaleMap = { "sk-SK": "sk", "cs-CZ": "cs", "en-US": "en-gb", "de-DE": "de" };

function updateObject(old, toUpdate) {
    return Object.assign({}, old, toUpdate);
}

function updateObjectProperty(old, propName, propValue) {
    const n = {...old};
    n[propName] = propValue;
    return n;
}

function addToArray(arr, obj, prop) {
    return mergeArrays(arr, [obj], prop)
}

function removeFromArray(arr, obj, prop) {
    return arr.filter(aitem => aitem[prop] !== obj[prop])
}

function mergeArrays(arr1, arr2, prop) {
    var reduced = arr1.filter(aitem => ! arr2.find(bitem => aitem[prop] === bitem[prop]))
    return reduced.concat(arr2);
}

function toName(arr) {
    return arr.map(x => String.fromCharCode(x)).join('');
}

function getNested(data, path) {
    let o = data;
    const p = path.split(/[.]/);
    for(let i = 0; i < p.length; i++) {
        if(o === null || o === undefined || !o.hasOwnProperty(p[i])) {
            return undefined;
        }
        o = o[p[i]];
    }
    return o;
}

function validateInput(e, onInput = null) {
    const { value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    e.target.value = sanitizedValue;

    if (onInput && typeof onInput === 'function') {
       onInput(e);
    }
}

function mergeInto (o1, o2) {
    for (var prop in o1) {
        if (o2.hasOwnProperty(prop)) {
            if (typeof(o1[prop]) === 'object' && typeof(o2[prop]) === 'object' )
                if (Array.isArray(o1[prop]) && Array.isArray(o2[prop]))
                    o1[prop] = o2[prop].slice(0);
                else
                    o1[prop] = mergeInto(o1[prop], o2[prop])
            else if (typeof(o1[prop]) === 'function' && typeof(o2[prop]) === 'function')
                o1[prop] = o2[prop];
            else if (typeof(o1[prop]) !== 'object' && typeof(o2[prop]) !== 'object' && typeof(o1[prop]) !== 'function' && typeof(o2[prop]) !== 'function')
                o1[prop] = o2[prop];
        }
    }
    return o1;
}

function updateSection(sections, path, updated) {
    var sid = path.shift();
    const tmpSections = isNullOrUndefined(sections) ? [] : [...sections];
    //is top section
    if (path.length === 0) {
        return tmpSections.map(sec => sec.id === sid ? updateObject(sec, updated) : sec);
    }
    return tmpSections.map(sec => sec.id === sid ? Object.assign({ ...sec }, { subsections: updateSection(sec.subsections, path, updated) }) : sec);
}

function tryParseInt(intVal, defaultVal) {
    try {
        let val = parseInt(intVal);
        return isNaN(val) ? defaultVal : val;
    } catch {
        return defaultVal;
    }
}

function isNullOrUndefined(obj) {
    return typeof obj === "undefined" || obj === null;
}

function isFunction(obj) {
    return typeof obj === 'function';
}

function isEmpty(obj) {
    return isNullOrUndefined(obj) || (obj.hasOwnProperty('length') && obj.length < 1);
}

function isString(s) {
    return Object.prototype.toString.call(s) === "[object String]";
}

function syncWithLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify({content:data}));
    return data;
}

function fetchFromLocalStorage(key, defaultValue) {
    if(localStorage.getItem(key) === null || localStorage.getItem(key) === undefined) {
        return syncWithLocalStorage(key, defaultValue);
    }
    return JSON.parse(localStorage[key]).content;
}

function toUtfString(str) {
    str = str.replace(/\r\n/g,"\n");
    let utftext = "";
    for (let n = 0; n < str.length; n++) {
        let c = str.charCodeAt(n);
        if (c < 128)
            utftext += String.fromCharCode(c);
        else if((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }
    }
    return utftext;
}

function padDate(x) { return (x < 10 ? '0' : '') + x; }

function getLanguage() {
    return localStorage.locales_lang || langMap[(window.location.hostname.match(/(.cz$)|(.sk$)|(.en$)|(.de$)/gi) || ['.sk'])] || "sk-SK";
};

function isValidLanguage(lang) {
    return !isNullOrUndefined(momentLocaleMap[lang]);
}

function getMomentLanguage(lang) {
    return momentLocaleMap[lang];
}

function getAuthorization(bearer) {
    if (localStorage === undefined || localStorage === null) {
        return "";
    }
    let auth = localStorage.getItem('auth');
    if (auth === null || auth === undefined) {
        return "";
    }
    auth = JSON.parse(auth);
    if (auth === null || auth === undefined || auth.token === null || auth.token === undefined) {
        return "";
    }

    return (bearer ? "Bearer " : "") + auth.token;
}

function openInNewWindow(url, name, width = 1024, height = null) {
    width = width > window.screen.width ? window.screen.width - 40 : width;
    height = height === null ? window.screen.height - 110 : height;
    window.open(url, name, `width=${width}, height=${height}, location=no, status=no, toolbar=no, menubar=no, scrollbars=yes, resizable=yes`);
}

function capitalize(s) {
    return isNullOrUndefined(s) || s.length === 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
}

function removeDiacritics(input, removeSpecial = true, removeStar = false) {
    if (isNullOrUndefined(input))
        return null;
    
    const diacriticChars = "áäčďéěíľĺňóöőôŕřšťúůýžÁÄČĎÉĚÍĽĹŇÓÖŐÔŔŘŠŤÚŮÝŽ";
    const normalizedChars = "aacdeeillnoooorrstuuyzAACDEEILLNOOOORRSTUUYZ";

    var otherChars = "";
    if (removeSpecial)
        otherChars = " .,;!@#$%^&():{}[]?></`~";
    if (removeStar)
        otherChars += "*";

    const sb = [];
    input.split("").forEach(f => {
        if (diacriticChars.includes(f))
            sb.push(normalizedChars[diacriticChars.indexOf(f)]);
        else if (!otherChars.includes(f))
            sb.push(f)
    });

    return sb.join("");
}

function getRandomValue() {
    var res;

    // First we're going to try to use a built-in CSPRNG
    if (window.crypto && window.crypto.getRandomValues) {
        res = new Uint32Array(1);
        window.crypto.getRandomValues(res);
    }
    // Because of course IE calls it msCrypto instead of being standard
    else if (window.msCrypto && window.msCrypto.getRandomValues) {
        res = new Uint32Array(1);
        window.msCrypto.getRandomValues(res);
    }
    // Last resort - we'll use isaac.js to get a random number. It's seeded from Math.random(),
    // so this isn't ideal, but it'll still greatly increase the space of guesses a hacker would
    // have to make to crack the password.
    else {
        res = [(((1 + isaac.random()) * 0x10000) | 0)];
    }

    return res[0];
}

function getRepiSetting(prop, defaultValue = null) {
    const auth = JSON.parse(localStorage.getItem('auth'));
    return (isNullOrUndefined(auth) || isNullOrUndefined(auth.repi) || isNullOrUndefined(auth.repi[prop]))
        ? defaultValue
        : auth.repi[prop];
}

function isRepiWeb() {
    let result = localStorage.getItem("is_repi_web");
    if (isNullOrUndefined(result)) {
        let value = false;
        const urls = window.repiWebUrls.split(";")
        urls.forEach(url => {
            try {
                const u = new URL(url);
                if (window.location.origin === u.origin)
                    value = true;
            } catch {
                writeErr(`Invalid URL '${url}'`)
            }
        });

        localStorage.setItem("is_repi_web", value);
        result = value;
    }

    return result === true || result === "true";
}

function reloadApiVersion(version) {
    if (!isEmpty(version))
        if (isNullOrUndefined(localStorage.apiVersion))
            localStorage.setItem("apiVersion", version);
        else if (localStorage.apiVersion !== version)
            window.location.reload();
}

export {
    updateObject, updateObjectProperty, getNested, mergeInto, tryParseInt, isNullOrUndefined, isFunction, toUtfString, padDate,
    getLanguage, getMomentLanguage, getAuthorization, addToArray, mergeArrays, removeFromArray, toName, updateSection,
    syncWithLocalStorage, fetchFromLocalStorage, openInNewWindow, isEmpty, isString, capitalize, removeDiacritics, getRandomValue, getRepiSetting, isRepiWeb,
    isValidLanguage, validateInput, reloadApiVersion
}